@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  color: rgb(22, 22, 22);
  --height-header: 50px;

  --gradient-color1: rgba(9, 32, 63, 0.9);
  --gradient-color2: rgba(83, 120, 149, 0.7);

  --base-text-color-l: 243, 243, 243;
  --base-lightgray: 238, 238, 238;
  --base-text-color-d: 22, 22, 22;
  --base--bg-color: 4, 16, 32;
  --color-green: 0, 255, 0;
  --color-green-dark: 6, 192, 6;
  --color-500: 4, 16, 31;
  --color-300: 9, 32, 63;

  --color-line: rgba(255, 255, 255, 0.2);
  --bg-color-table-01: rgba(255, 255, 255, 0.1);
  --bg-color-table: rgba(255, 255, 255, 0.2);
  --bg-color-table-hover: rgba(255, 255, 255, 0.4);
  --bg-color-task: rgb(252, 255, 221);
  --bg-color-task-hover: rgb(249, 255, 184);
  --bg-color-dark-hover: rgba(0, 0, 0, 0.2);
  --mask-color-dark01: rgba(0, 0, 0, 0.05);
  --mask-color-dark02: rgba(0, 0, 0, 0.2);

  --hight-priority: rgb(255, 38, 38);
  --medium-priority: rgb(255, 255, 25);
  --low-priority: rgb(50, 201, 50);
  --undefined-priority: rgb(228, 228, 228);

  --shadow: 0 0 16px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.1);
  --shadow-up: 0 0 14px rgba(0, 0, 0, 0.2), 0 0 12px rgba(0, 0, 0, 0.2);
  --shadow-card: 2px 2px 6px rgba(0, 0, 0, 0.2), 2px 2px 3px rgba(0, 0, 0, 0.2);
  --shadow-card-up: 4px 4px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.4);

  --aside-gradient: linear-gradient(180deg, var(--gradient-color1) 0%, var(--gradient-color2) 100%);

  --radius-small: 4px;
  --radius-medium: 6px;
  --radius-large: 8px;

  --scrollbar-track: rgba(83, 120, 149, 0.6);
  --scrollbar-thumb: rgb(9, 32, 63);
}
body {
  font-family: -apple-system, 'Source Sans Pro', 'Roboto', 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100vw;
  height: 100vh;

  color: rgb(var(--base-text-color-l));
  background-color: rgb(var(--color-500));
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.5);
  border-radius: 50px;
}
